<template>
  <div class="announcements-add add-template">
    <g-background>
      <page-header>
        <h1>{{ translations.tcAddCampAnnouncement }}</h1>
      </page-header>
      <page-body>
        <AnnouncementTemplate
          :form="form"
          :showButtons="true"
          :nextButtonText="translations.tcSubmit"
          @announcement_validated="handleSubmit"
          @announcement_cancel="handleCancelClick"
          @backClick="handleBackClick"
        >
          <slot>
            <div class="form-section">{{ announcementRecipient }}</div>
            <div class="button-group">
              <b-button
                :variant="form.recipients === 'Both' ? 'primary' : 'outline'"
                @click="handleRecipientsChange(0)"
                class="grid btn-w-sm"
                >{{ translations.tcAllMembers }}</b-button
              >
              <b-button
                :variant="form.recipients === 'Gideon' ? 'primary' : 'outline'"
                @click="handleRecipientsChange(1)"
                class="month btn-w-sm"
                >{{ translations.tcGideonsOnly }}</b-button
              >
              <b-button
                :variant="form.recipients === 'Auxiliary' ? 'primary' : 'outline'"
                @click="handleRecipientsChange(2)"
                class="month btn-w-sm"
                >{{ translations.tcAuxiliaryOnly }}</b-button
              >
            </div>
          </slot>
        </AnnouncementTemplate>
      </page-body>
    </g-background>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnouncementTemplate from '@/components/AnnouncementTemplate.vue'
import gBackground from '@/components/gBackground.vue'
import iDownArrow from '@/assets/svgs/icon-down-arrow.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-camp-announcement',
  mixins: [translationMixin],
  data() {
    return {
      form: {
        recipients: 'Both',
      },
      translations: {},
    }
  },
  components: {
    AnnouncementTemplate: AnnouncementTemplate,
    gBackground: gBackground,
    iDownArrow: iDownArrow,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
  methods: {
    ...mapActions({
      addUpdateAnnouncementMessage: 'announcements/addUpdateAnnouncementMessage',
      resetMembers: 'announcements/resetMembers',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMembers: 'announcements/setMembers',
      setMessagePackageAudience: 'announcements/setMessagePackageAudience',
      setMessagePackageGeography: 'announcements/setMessagePackageGeography',
      setMessagePackageMessage: 'announcements/setMessagePackageMessage',
    }),
    handleBackClick() {
      this.$router.push('/get-connected/stay-informed/announcements')
    },
    async handleCancelClick() {
      this.$router.push('/get-connected/stay-informed/announcements')
    },
    async handleRecipientsChange(fltr) {
      this.form.recipients = ['Both', 'Gideon', 'Auxiliary'][fltr]
      await this.setMembers(this.form.recipients)
    },
    async handleSubmit() {
      try {
        await this.setLoadingStatus(true)
        await this.setMessagePackageMessage({
          user: this.userId,
          sendername: this.demograph.informal_name + ' ' + this.demograph.last_name,
          senderemail: this.demograph.email,
        })
        await this.setMessagePackageGeography({
          statekey: this.userStateKey,
          location: this.userCamp.key,
          location_name: this.userCamp.name,
          type: 'Camp',
          user: this.userId,
        })
        await (async () => {
          let members =
            this.form.recipients === 'Both'
              ? this.campBothMembers
              : this.form.recipients === 'Gideon'
              ? this.campGideonMembers
              : this.campAuxiliaryMembers

          members.map((sbm) => {
            if (sbm.areas[0].selected) {
              this.setMessagePackageAudience({
                audience: sbm.areas[0].value,
                type: 'Camp',
                user: this.userId,
              })
            }
          })
        })()

        if (
          await this.addUpdateAnnouncementMessage({
            include: false,
            membertype: this.form.recipients.substr(0, 1).toLowerCase(),
            messageid: this.form.msg_message_id,
          })
        ) {
          this.$swal({
            icon: 'success',
            text: this.translations.tcAnnouncementSaved,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then(() => {
            this.$router.push('/get-connected/stay-informed/announcements')
          })
        } else {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorAnnouncementNotSaved,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async loadUp() {
      await (async () => {
        this.form.aud_array = this.announcementForm.aud_array
        this.form.msg_message_id = this.announcementForm.msg_message_id
        this.form.msg_member_type = this.announcementForm.msg_member_type
        this.form.notification = this.announcementForm.notification
        this.form.title = this.announcementForm.title
        this.form.expires = this.announcementForm.expires
        this.form.message = this.announcementForm.message
        this.form.recipients = { b: 'Both', a: 'Auxiliary', g: 'Gideon' }[this.announcementForm.msg_member_type || 'b']
        this.form.fileArray = this.announcementForm.fileArray
        this.form.tempFileArray = this.announcementForm.tempFileArray
        this.presetDate =
          this.announcementForm.expires === null || this.announcementForm.expires.toString().includes('0001')
            ? null
            : this.announcementForm.expires
      })()
    },
  },
  computed: {
    ...mapGetters({
      announcementForm: 'announcements/announcementForm',
      campBothMembers: 'announcements/stateBothMembers',
      campAuxiliaryMembers: 'announcements/stateAuxiliaryMembers',
      campGideonMembers: 'announcements/stateGideonMembers',
      campMemberTypeId: 'announcements/campMemberTypeId',
      demograph: 'user/demograph',
      msg_pkg: 'announcements/msg_pkg',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCamp: 'user/userCamp',
      userId: 'user/userId',
      userStateKey: 'user/userStateKey',
    }),
    announcementRecipient() {
      return `${this.translations.tcTo}: ${
        !!this.officerToolbarSelected.camp_name ? this.officerToolbarSelected.camp_name : this.userCamp.name
      } ${this.translations.tcCamp}`
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        await this.loadUp(),
        await this.resetMembers(),
        await (this.members = this.campBothMembers),
      ])
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.add-template {
  @include breakpoint(sm) {
    .page-header {
      text-align: center;
      h1 {
        font-size: 42px;
      }
    }
  }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
  }
  .form-section {
    font-size: 22px;
    font-weight: bold;
    display: block;
    line-height: 44px;
    letter-spacing: 0.73px;
    text-transform: uppercase;
    color: #636363;
    margin-bottom: 10px;
  }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: #636363;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        margin-bottom: 15px !important;
        // &:last-of-type {
        //   margin-bottom: 0 !important;
        // }
      }
    }
  }
}

.announcements-add {
  #title-input::placeholder {
    text-transform: uppercase;
    font-weight: bold;
  }
  .to-expire {
    margin-bottom: 40px;
    margin-top: 25px;
    justify-content: space-between;
    @include breakpoint(sm) {
      margin-bottom: 10px;
      flex-wrap: wrap;
      > div {
        &:first-of-type {
          margin-bottom: 60px;
        }
        &:last-of-type {
          width: 100%;
          .expires-date {
            text-transform: none;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
  .button-group {
    .btn {
      font-weight: normal;
      text-transform: none;
      @include breakpoint(sm) {
        width: 50%;
      }
      &.btn-primary {
        font-weight: 800;
      }
    }
  }
  .expires-date {
    color: #636363;
    font-weight: normal;
    svg {
      margin-right: 15px;
      height: 20px;
    }
  }
  .form-attachment {
    .attachment-list {
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
    }
    @include breakpoint(sm) {
      .btn {
        width: auto !important;
      }
    }
  }
  .start-end-app .start-input input[type='date'] {
    padding: 10px 20px 10px 60px;
  }

  .start-end-app {
    .start-input {
      &:after {
        content: 'Expiration Date';
      }
    }
  }
}
</style>
